var touchstartX = 0;
var touchendX = 0;

(function() {
  window.addEventListener("scroll", function() {
    var top = document.getElementById('top');
    var classList = Array.from(top.classList);

    if (!classList.includes('top--scrolled') && window.scrollY > 150) {
      top.classList.add('top--scrolled');
    }

    if (classList.includes('top--scrolled') && window.scrollY < 150) {
      top.classList.remove('top--scrolled');
    }
  });

  document.querySelectorAll('.mobile-nav a').forEach(function(mobileNav) {
    mobileNav.addEventListener("click", function() {
      closeNav();
    });
  });
  
  document.querySelector('.portfolio__scroll').addEventListener("touchstart", (e) => {
    touchstartX = e.changedTouches[0].screenX;
  });

  document.querySelector('.portfolio__scroll').addEventListener("touchend", (e) => {
    touchendX = e.changedTouches[0].screenX;

    console.log({
      touchendX,
      touchstartX,
    })
    
    if (touchendX < touchstartX && (touchstartX - touchendX) > 50) {
      portfolioRight();
    }
    if (touchendX > touchstartX && (touchendX - touchstartX) > 50) {
      portfolioLeft();
    }
  });
})();

var portfolioItem = 0;
var grid = document.getElementById('portfolio-grid');
window.portfolioRight = function() {
  var elements = document.getElementsByClassName('portfolio-item');
  Array.from(elements).some(function(el) {
    if (el.offsetLeft > grid.scrollLeft + window.innerWidth/2) {
      grid.scrollTo({
        top: 0,
        left: el.offsetLeft,
        behavior: "smooth",
      });
      return el;
    }
  });
}
window.portfolioLeft = function() {
  var elements = document.getElementsByClassName('portfolio-item');
  Array.from(elements).some(function(el) {
    if (el.offsetLeft > grid.scrollLeft - window.innerWidth*1.5) {
      grid.scrollTo({
        top: 0,
        left: el.offsetLeft,
        behavior: "smooth",
      });
      return el;
    }
  });
}

window.openNav = function() {
  document.getElementById("mobile-nav").classList.add('mobile-nav--active');
}

window.closeNav = function() {
  document.getElementById("mobile-nav").classList.remove('mobile-nav--active');
}

window.openLightbox = function() {
  document.getElementById("lightbox").classList.add('lightbox--active');
  document.getElementById("lightbox-embed").innerHTML = "<iframe src='https://www.youtube.com/embed/iBOueF58awQ' title='Yasen Upwork' frameborder='0' allowfullscreen='' />";
}

window.closeLightbox = function() {
  document.getElementById("lightbox").classList.remove('lightbox--active');
  document.getElementById("lightbox-embed").innerHTML = "";
}
